import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { OraculoService } from '../../shared/oraculo.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-arvore-alocacao',
  templateUrl: './arvore-alocacao.component.html',
  styleUrls: ['./arvore-alocacao.component.scss'],
})
export class ArvoreAlocacaoComponent implements OnInit {

  @Input()
  configuration: any;

  edit = false;

  @Input()
  valorTotalFormControl: FormControl = new FormControl();

  valorTotal: number = 0;
  mode: string = 'arvore';


  constructor(
    public oraculoService: OraculoService,
  ) {
  }
  ngOnInit() {
    this.valorTotalFormControl.valueChanges.subscribe((valor) => {
      this.valorTotal = Number((valor || '0').replace('R$ ', '').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace(',', '.'));
    });
  }
}
