export function suggestionsOraculo(data: any, valorAdicional: any = 0, ) {
    
    let suggetions = Object.keys(data.information).map((key) => {
        let keys = key.split('_');
        
        let finalValue = keys.reduce((prev: any, key) => {
            
            prev.configuration = prev.configuration.children.find((configuration) => configuration.name == key);
            let number = prev.configuration?.value ?? 0;
            prev.percentage = prev.percentage * (number / 100);
            return prev;
        }, {
            percentage: 1,
            configuration: data.configuration,
        });
        return data.information[key].map((item) => {
            if (item.valorAtual == undefined) {
                item.valorAtual = 0;
            }
            item.key = key;
            item.keys = keys;
            item.percentageOnTotal = finalValue.percentage;
            item.valorDesejado = (data.valorTotal + Number(valorAdicional)) * item.percentageOnTotal;
            item.diferenca = item.valorDesejado - convertAllToNumber(item.valorAtual);
            return item;
        });
    })
        .reduce((prev, curr) => {
            
            return [
                ...prev,
                ...curr,
            ];
        }, [])
        .sort(function (a, b) {
            return b.diferenca - a.diferenca;
        });
   
    return [];
}
function convertAllToNumber(
    value: string,
) {
    if (value != undefined && value != null) return Number(value);
    return 0;
}
export function groupBy(lista, coluna) {
    var colunas = {};
    var resultado = [];

    lista.forEach(function (item) {
        var reg = {};

        colunas[item[coluna]] = colunas[item[coluna]] || [];

        for (var i in item)
            if (i != coluna)
                reg[i] = item[i];

        colunas[item[coluna]].push(reg);
    });

    for (var i in colunas)
        resultado.push({ key: i, values: colunas[i] });

    return resultado;
}
if ((Array.prototype as any).equals)
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
// attach the .equals method to Array's prototype to call it on any array
(Array.prototype as any).equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time 
    if (this.length != array.length)
        return false;

    for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        }
        else if (this[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });