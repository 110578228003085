<mat-nav-list>

  <div *ngIf="activeFolder === true" style="
  padding-top: 24px;
  padding-left: 18px;
  margin-bottom: -20px;
">
    <button (click)="backFolder()" mat-stroked-button color="primary" style="border-radius: 30px; margin-bottom: 24px; color: #109cf1;">
      <svg style="width:24px;height:24px; float: left; margin-top: 5px; margin-right: 6px;" viewBox="0 0 24 24">
      <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
  </svg>
  Voltar para TechFinance
  </button>
  </div>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/oraculo">
    <mat-icon matListIcon>tune</mat-icon>
    Oráculo
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/configurar-carteira">
    <mat-icon matListIcon>assessment</mat-icon>
    Portfólio Meta
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/portfolio-atual">
    <mat-icon matListIcon>list</mat-icon>
    Portfólio Atual
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/inicio">
    <mat-icon matListIcon>analytics</mat-icon>
    Dashboards
  </a>

  <a mat-list-item routerLinkActive="list-item-active" href="https://empreenderdinheiro.com.br/perfis-masterplan/"
    target="_blank">
    <mat-icon matListIcon>launch</mat-icon>
    Estratégias de Referência
  </a>

  <a mat-list-item (click)="openModal()">
    <mat-icon matListIcon>play_circle_outline</mat-icon>
    Comece por aqui
  </a>

  <a *ngIf="origin=='empreenderdinheiro' && activeFolder === false" mat-list-item routerLinkActive="list-item-active"
    [href]="['https:/', 'membro.' +  baseUrl , accessToken].join('/')">
    <mat-icon matListIcon>assignment_return</mat-icon>
    Retornar ao Investidor 5.0
  </a>
  <a *ngIf="origin=='techfinance' && activeFolder === false" mat-list-item routerLinkActive="list-item-active"
    [href]="['https:/', 'techfinance.' +  baseUrl , accessToken].join('/')">
    <mat-icon matListIcon>assignment_return</mat-icon>
    Retornar à TechFinance
  </a>

  <div class="footer">
    Powered by Empreender Dinheiro
  </div>

</mat-nav-list>