import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { configurationAssets, colors } from './../portolio-configuracao/configuration';
import { OraculoService } from '../../shared/oraculo.service';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-portolio-configuracao',
  templateUrl: './portolio-configuracao.component.html',
  styleUrls: ['./portolio-configuracao.component.scss']
})
export class PortolioConfiguracaoComponent implements OnInit {

  configuration = configurationAssets;
  configurationGroup: FormGroup;
  step = 0;
  color: any = colors;
  constructor(private cdr: ChangeDetectorRef, public oraculoService: OraculoService, public router: Router, ) { }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    for (var i = this.step + 1; i < 9; i++) {
      if (document.querySelector("[id-item='" + i + "']") != undefined) {
        setTimeout(() => {
          document.querySelector("[id-item='" + i + "']").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 480);
        this.step = i;
        return;
      }
    }
    this.step = 20;
   
  }

  prevStep() {
    this.step--;
  }
  setPanel(e, step) {
    this.step = step;
  }

  ngOnInit(): void {
    this.configurationGroup = (this.buildConfiguration(this.configuration));
   
    this.configurationGroup.valueChanges.subscribe((data) => this.cdr.detectChanges());

    this.oraculoService.getConfigurationMeta().subscribe((data) => {
      if (data == null) {
        this.configurationGroup.setValue({
          ...this.configurationGroup.value,
          value: 0,
        });
        return;
      }
      
      this.configurationGroup.patchValue(data);
      this.configurationGroup.markAllAsTouched();
    });


  }

  onSave() {
    this.oraculoService.saveConfigurationMeta(this.configurationGroup.value);
    this.oraculoService.setStepper('carteira', true)
      .subscribe((data) => {
        this.router.navigate(['/inicio']);
      });

  }
  buildConfiguration(configuration: any) {
    let formGroup = new FormGroup({
      name: new FormControl(configuration.name),
      color: new FormControl(configuration.color),
      disclaimer: new FormControl(configuration.disclaimer),
      aviso: new FormControl(configuration.aviso),
      value: new FormControl(null, [Validators.maxLength(4), Validators.max(100)]),
    }, {
      validators: (fn) => {


        if (fn.get('value').value == null) return null;
        if (fn.get('children') == undefined) return null;

        if (fn.get('value')?.value == 0) return null;


        let sumChildren = (fn.get('children') as any).controls.map((control) => control.value.value).filter((item) => Number(item)).reduce((prev, curr) => Number(prev) + Number(curr), 0);

        if (sumChildren == 100) {
          return null;
        }
        return { totalEqual100: true };
      }
    });
    if (configuration.children != undefined) {
      formGroup.addControl('children', new FormArray(configuration.children.map((child) => this.buildConfiguration(child))));
      //Autocomplete Last

      formGroup.get('children').valueChanges
        .pipe(debounceTime(700))
        .subscribe((values) => {
          let totalValue = values.reduce((prev, item) => prev + item.value, 0);
          
          if (totalValue < 100) {
            if (values.filter((item) => item.value == null).length == 1) {
              let getIndex = values.reduce((prev, item, key) => {
                if (item.value == null) {
                  return key;
                }
                return prev;
              }, false);
              
              if (getIndex) {
                (formGroup.get('children') as any).controls[getIndex].get('value').setValue(100 - totalValue);
              }
            }
          }
        });
    }
    return formGroup;
  }

}
