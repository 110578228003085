import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-ativos-meta',
  templateUrl: './ativos-meta.component.html',
  styleUrls: ['./ativos-meta.component.scss']
})
export class AtivosMetaComponent implements OnInit {

  ativos: FormArray = new FormArray([], {
    validators: (fn) => {
      if (this.somaTotal === 100) {
        return null;
      }
      return { sumTotal: true };
    }
  });
  @Input('name')
  name: string;
  @Input('values')
  values: any;
  @Output('changeAtivos')
  changeAtivos: EventEmitter<any> = new EventEmitter();

  options: string[] = [];


  constructor() { }

  ngOnInit(): void {

    if (this.values != undefined) {
      this.buildInitialValues();
    }
    this.ativos.valueChanges.subscribe((value) => {
      
      if (this.ativos.valid) {
        this.changeAtivos.emit(
          {
            [this.name]: this.ativos.value,
          }
        );
        return;
      }
      this.changeAtivos.emit(null);
    });
  }
  buildInitialValues() {
    this.values.forEach((value) => {
      this.ativos.push(
        new FormGroup({
          name: new FormControl(value.name, [Validators.minLength(1)]),
          descricao: new FormControl(value.descricao),
          percentageMeta: new FormControl(value.percentageMeta),
        }),
      );
    });
  }
  addAtivo() {
    this.ativos.push(
      new FormGroup({
        name: new FormControl(null, [Validators.minLength(1)]),
        descricao: new FormControl(),
        percentageMeta: new FormControl(),
      }),
    );
  }
  get somaTotal() {
    return this.ativos?.controls.reduce((prev, current) => {
      return prev + current.value.percentageMeta;
    }, 0);
  }
}
