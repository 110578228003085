import { color } from "highcharts";
import { buildValue } from "../modules/oraculo/oraculo-calc.service";
export let colors = {
    "Renda Fixa": {
        "backgroundColor": "rgb(13, 156, 241)",
        "fontColor": "#FFF",
        "borderColor": "#000",
        levels: [
            {
                "backgroundColor": "rgb(234, 246, 255)",
                "borderColor": "rgb(13, 156, 241)"
            }
        ]
    },
    "Renda Váriavel": {
        "backgroundColor": "rgb(0, 192, 141)",
        "fontColor": "#FFF",
        "borderColor": "rgb(0, 192, 141)",
        levels: {
            "Ações": {
                "backgroundColor": "rgb(211, 233, 211)",
                "borderColor": "rgb(0, 192, 141)",
                levels: {
                        "Brasil": {
                            "backgroundColor": "rgb(238, 249, 238)",
                            "borderColor": "rgb(0, 192, 141)"
                        },
                        "Internacional": {
                            "backgroundColor": "rgb(238, 249, 238)",
                            "borderColor": "rgb(48, 134, 80)"
                        },
                },
            },
            "Imóveis": {
                "backgroundColor": "rgb(217, 228, 217)",
                "borderColor": "rgb(48, 134, 80)",
                levels: {
                    "Brasil": {
                        "backgroundColor": "rgb(238 249 238)",
                        "borderColor": "rgb(48, 134, 80)"
                    },
                    "Exterior": {
                        "backgroundColor": "rgb(238 249 238)",
                        "borderColor": "rgb(48, 134, 80)"
                    },
            },
        },
            "Proteção": {
                "backgroundColor": "rgb(251, 231, 205)",
                "borderColor": "rgb(237, 138, 9)"
            },
            "Criptoativos": {
                "backgroundColor": "rgb(252, 241, 223)",
                "borderColor": "rgb(238, 188, 102)"
            },
            "Fundos MMs & Outros": {
                "backgroundColor": "rgb(244, 203, 210)",
                "borderColor": "rgb(203, 1, 34)"
            },
        }
    },
    
    
};
export function ultimateReportMap(payload) {

    function recursiveKeyHeader(
        key: Array<String>
    ) {
        return function recursiveKey(
            object
        ) {
            object.key = [...key, object.name];
            if (object.children !== undefined) {
                object.children = object.children.map(recursiveKeyHeader(object.key))
            }
            return object;
        }
    }
    payload.configuration.children = payload.configuration.children.map(recursiveKeyHeader([]));

    function analiseItem(
        prev,
        object
    ) {
        const ativosAtuais = payload.atuais[object.key.join(`_`)];
        let actual = 0;
        if (ativosAtuais !== undefined) {
            actual = ativosAtuais.reduce((prev, curr) => prev + curr.valorAtual, 0);
        }
        const newItem = {
            label: object.name,
            "goal": object.value,
            "actual": actual,
            "color": object.color
        };
        return [
            ...prev,
            newItem,
        ];
    }

    function recursiveResultRebalance(
        object
    ) {
        const keys = object.key;

        const ativosAtuais = payload.atuais[keys.join(`_`)];
        let actual = 0;
        if (ativosAtuais !== undefined) {
            actual = ativosAtuais.reduce((prev, curr) => prev + curr.valorAtual, 0);
        }
        let calcObject = buildValue(keys, payload, payload.valorTotal);
        let color: any = {};
        if(keys.length == 1) {
            color = colors[keys[0]];
        }
        else if(keys.length > 1) {
            if(colors[keys[0]].levels.length > 0) {
                color = colors[keys[0]].levels[0];
            } else if(keys.length==2) {
                color = colors[keys[0]].levels[object.name];
            } else if(keys.length==3) {
                let preColor = colors[keys[0]].levels[keys[1]];
                if(preColor.levels!==undefined) {
                    color = preColor.levels[object.name];
                }
            }
        }


        return {
            "backgroundColor": color['backgroundColor'],
            "fontColor": color['fontColor'],
            "borderColor": color['borderColor'],
            "description": object.name,
            "investedAmount": actual,
            "walletPercent": calcObject.percentageCarteira * 100,
            "objectivePercent": calcObject.percentageMeta.percentageOnItem * 100,
            "differencePercent": calcObject.diferencaOnItem * 100,
            "objectiveAmount": calcObject.objetivo,
            backgroundSellColor: (calcObject.falta >= 0 ? (calcObject.falta == 0 ? `red`: `#2D9BDF`) : `#E5D6C1`),
            "suggestion": (calcObject.falta >= 0 ? (calcObject.falta == 0 ? ``: `buy`) : `sell`),
            "buySell": calcObject.falta,
            "subItems": object.children === undefined ? [] : object.children.map(recursiveResultRebalance)
        };
    }


    const resultRebalance = payload.configuration.children.map(recursiveResultRebalance);

    function recursivePortfolioMeta(
        object
    ) {
        return {
            title: object.name,
            value: (payload.valorTotal * (object.value / 100)),
            "colorTag": object.color,
            subCards: (object.children === undefined || object.children.length == 0) ? undefined : object.children.map(recursivePortfolioMeta),
        };
    }

    const portifolio = Object.values(payload.atuais).reduce((prev: any, curr: any) => {
        return [
            ...prev,
            ...curr.map((item) => {
               
                return {
                    "color": "#0D9CF1",
                    "classActive": item.name.split(`_`).join(`/`),
                    "description": item.descricao,
                    "amount": item.valorAtual
                };
            }),
        ];
        
    }, []);

    return {
        "informations": {
            "name": "Junior Anzolin",
            "consultant": "Guilherme Henrique",
            "date": new Date(),
            "emissionDate": {
                "date": "ME ALTERA PRA date",
                "time": "18:33"
            }
        },
        "totalWalletBalance": payload.valorTotal,
        "analysis": [
            {
                "title": "ANÁLISE DAS ALOCAÇÕES",
                "items": [
                    {
                        "title": "Alocação Principal",
                        "metrics":
                            payload.configuration.children
                                .reduce(analiseItem, [])
                    },
                    {
                        "title": "Portfólio de Renda Fixa",
                        "metrics":
                            payload.configuration.children[0].children
                                .reduce(analiseItem, [])
                    },
                    {
                        "title": "Portfólio de Renda Variável",
                        "metrics":
                            payload.configuration.children[1].children
                                .reduce(analiseItem, [])
                    },
                    {
                        "title": "Portfólio de Ações-Brasil",
                        "metrics":
                            payload.configuration.children[1].children[0].children[0].children
                                .reduce(analiseItem, [])
                    },
                    {
                        "title": "Portfólio de Ações-Internacional",
                        "metrics":
                            payload.configuration.children[1].children[0].children[1].children
                                .reduce(analiseItem, [])
                    }
                ]
            },
            {
                "title": "ANÁLISE DAS ALOCAÇÕES (CONT.)",
                "items": [
                    {
                        "title": "Imóveis-Brasil",
                        "metrics": payload.configuration.children[1].children[1].children[0].children
                            .reduce(analiseItem, [])
                    },
                    {
                        "title": "Imóveis-Exterior",
                        "metrics": payload.configuration.children[1].children[1].children[1].children
                            .reduce(analiseItem, [])
                    },
                    {
                        "title": "Proteção",
                        "metrics": payload.configuration.children[1].children[2].children
                            .reduce(analiseItem, [])
                    },
                    {
                        "title": "Criptoativos",
                        "metrics": payload.configuration.children[1].children[3].children
                            .reduce(analiseItem, [])
                    },
                    {
                        "title": "Fundo MMs & Outros",
                        "metrics": payload.configuration.children[1].children[4].children
                            .reduce(analiseItem, [])
                    }
                ]
            }
        ],
        "resultRebalanceHeader": [
            "CATEGORIA",
            "VALOR INVESTIDO",
            "CARTEIRA (%)",
            "OBJETIVO (%)",
            "DIFERENÇA (%)",
            "OBJETIVO",
            "SUGESTÕES",
            "COMPRAR/VENDER"
        ],
        resultRebalance: [resultRebalance[0]],
        "resultRebalanceCont": [resultRebalance[1]],
        "portifolio": portifolio,
        portFolioMeta: {
            title: 'Seu portfólio meta',
            value: payload.valorTotal,
            colorTag: 'black',
            subCards: payload.configuration.children.map(recursivePortfolioMeta),
          },
    }
}